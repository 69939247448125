import React, { lazy, Suspense, useEffect } from 'react';
import { render } from 'react-dom';
import T from 'prop-types';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
import { DevseedUiThemeProvider } from '@devseed-ui/theme-provider';
import { GlobalLoadingProvider } from '@devseed-ui/global-loading';

import GlobalStyle from './styles/global';

import history from './utils/history';
import { themeOverridesOCI } from './styles/theme';

// Views
const UhOh = lazy(() => import('./components/uhoh'));
const ErrorBoundary = lazy(() => import('./components/uhoh/fatal-error'));
const Home = lazy(() => import('./components/home'));
const AboutUs = lazy(() => import('./components/aboutus'));
const DemoVideos = lazy(() => import('./components/demo-videos'));
const Models = lazy(() => import('./components/models'));
const TermsOfUse = lazy(() => import('./components/terms-use'));
const Methodology = lazy(() => import('./components/methodology'));
const TotalEmissions = lazy(() => import('./components/total-emissions'));
const SupplyChain = lazy(() => import('./components/supply-chain/index'));
const Analysis = lazy(() => import('./components/analysis/index'));
const MapPage = lazy(() => import('./components/map'));
const OilDetail = lazy(() => import('./components/oil/index'));
const CompareOil = lazy(() => import('./components/compare-oil/index'));
const Glossary = lazy(() => import('./components/glossary'));
const Scenarios = lazy(() => import('./components/scenarios/hub'));
const EachScenario = lazy(() => import('./components/scenarios/single'));
const Studies = lazy(() => import('./components/studies'));

// Contexts
import { BaseDataProvider } from './context/base-data';

const composingComponents = [
  // Add contexts here.
  ErrorBoundary,
  BaseDataProvider
];

function ScrollTop() {
  const { pathname, hash } = useLocation();
  useEffect(() => {
    if (hash) {
      return;
    }

    window.scrollTo({ top: 0 });
  }, [hash, pathname]);
  return null;
}

function Fallback() {
  return <h1>Loading</h1>;
}

// Root component.
function Root() {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--scrollbar-width',
      window.innerWidth - document.documentElement.clientWidth + 'px'
    );
  }, []);

  useEffect(() => {
    // Hide the welcome banner.
    const banner = document.querySelector('#welcome-banner');
    banner.classList.add('dismissed');
    setTimeout(() => banner.remove(), 500);
  }, []);

  return (
    <Suspense fallback={<Fallback />}>
      <Router history={history}>
        <DevseedUiThemeProvider theme={themeOverridesOCI}>
          <GlobalLoadingProvider />
          <ScrollTop />
          <GlobalStyle />
          <Composer components={composingComponents}>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/about/demovideos' component={DemoVideos} />
              <Route exact path='/about/models' component={Models} />
              <Route exact path='/about/termsofuse' component={TermsOfUse} />
              <Route exact path='/about/us' component={AboutUs} />
              <Route exact path='/about/studies' component={Studies} />
              <Route exact path='/methodology' component={Methodology} />
              <Route exact path='/total-emissions' component={TotalEmissions} />
              <Route exact path='/supply-chain' component={SupplyChain} />
              <Route exact path='/analysis' component={Analysis} />
              <Route exact path='/scenarios' component={Scenarios} />
              <Route path='/scenarios/:caseId' component={EachScenario} />
              <Route exact path='/map' component={MapPage} />
              <Route path='/oil/:oilId' component={OilDetail} />
              <Route
                path='/compare/:oilId/:oilToCompareId'
                component={CompareOil}
              />
              <Route exact path='/glossary' component={Glossary} />
              <Route path='*' component={UhOh} />
            </Switch>
          </Composer>
        </DevseedUiThemeProvider>
      </Router>
    </Suspense>
  );
}

render(<Root />, document.querySelector('#app-container'));

/**
 * Composes components to to avoid deep nesting trees. Useful for contexts.
 *
 * @param {node} children Component children
 * @param {array} components The components to compose.
 */
function Composer(props) {
  const { children, components } = props;
  const itemToCompose = [...components].reverse();

  return itemToCompose.reduce(
    (acc, Component) => <Component>{acc}</Component>,
    children
  );
}

Composer.propTypes = {
  components: T.array,
  children: T.node
};

Object.defineProperty(Array.prototype, 'last', {
  enumerable: false,
  configurable: true,
  get: function () {
    return this[this.length - 1];
  },
  set: undefined
});
