// this file used was required to be able to load from gulpfile before.
// now it is serving as a configuration file for the app
const _ = require('lodash');

/*
 * App configuration.
 *
 * Uses settings in config/production.js, with any properties set by
 * config/staging.js or config/local.js overriding them depending upon the
 * environment.
 *
 * This file should not be modified.  Instead, modify one of:
 *
 *  - config/production.js
 *      Production settings (base).
 *  - config/staging.js
 *      Overrides to production if ENV is staging.
 *  - config/local.js
 *      Overrides if local.js exists.
 *      This last file is gitignored, so you can safely change it without
 *      polluting the repo.
 */

// The production config works as base.
let config = require('./config/production');

if (process.env.NODE_ENV === 'staging') {
  config = _.defaultsDeep(require('./config/staging'), config);
}

if (process.env.NODE_ENV === 'development') {
  config = _.defaultsDeep(require('./config/local'), config);
}

// ENV variables overrides.
config.baseUrl = process.env.PUBLIC_URL || config.baseUrl || '';

module.exports = config;
