import React, { createContext, useContext, useState, useEffect } from 'react';
import T from 'prop-types';
import _ from 'lodash';
import axios from 'axios';

import { makeAbsUrl } from '../utils/history';

const axiosData = axios.create({
  baseURL: makeAbsUrl('/assets/data')
});

// Context
export const BaseDataContext = createContext(null);

// Context provider
export const BaseDataProvider = ({ children }) => {
  const [baseDataLoading, setBaseDataLoading] = useState(false);
  const [baseData, setBaseData] = useState({});

  useEffect(() => {
    async function dataLoader() {
      setBaseDataLoading(true);
      const [metadata, blurbs, oilFields, prices, related, infoRaw, scenarios] =
        await Promise.all([
          axiosData.get('metadata.json'),
          axiosData.get('blurbs.json'),
          axiosData.get('oilfields.geojson'),
          axiosData.get('prices.json'),
          axiosData.get('related.json'),
          axiosData.get('info.json'),
          axiosData.get('scenarios.json')
        ]);

      const regions = _(infoRaw.data).map('Region').uniq().value();

      const opecCountries = _(infoRaw.data)
        .filter((e) => e.OPEC == 'Y')
        .map('Country')
        .map((e) => e.trim())
        .uniq()
        .value();

      const countries = _(infoRaw.data).map('Country').uniq().value();
      const types = _(infoRaw.data).map('Resource Type').uniq().value();

      const info = infoRaw.data;

      // Format model parameters - Convert to array to simplify usage.
      const modelParameters = _.map(metadata.data, (param, id) => {
        // Convert the param values for visual representation.
        // The parameter values are stored in a string format like "1, .5, 2".
        // These values need to be converted and ordered before being displayed
        // to the user as selection options, but the value index (after
        // splitting by comma) needs to be stored, because this value is used to
        // create the name of the model to load.
        // There are also come specific cases that require special handling.

        // This code standardizes the values converting "1, .5, 2" to:
        // [
        //   {
        //     "value": 1,
        //     "origVal": 0.5,
        //     "label": "Low",
        //     "initial": false
        //   },
        //   {
        //     "value": 0,
        //     "origVal": 1,
        //     "label": "Default",
        //     "initial": true
        //   },
        //   {
        //     "value": 2,
        //     "origVal": 1.5,
        //     "label": "High",
        //     "initial": false
        //   }
        // ]
        const paramValues = _(param.values)
          .split(',')
          .map(Number)
          .thru((values) => {
            if (param.type === 'slider') {
              if (['venting', 'fugitives'].includes(id)) {
                const labels = ['Low', 'Default', 'High'];
                return (
                  _(values)
                    // map to ensure a common structure.
                    .map((v, i) => ({
                      value: i,
                      origVal: v,
                      label: v,
                      initial: i === 0
                    }))
                    // Sort the values
                    .sortBy('origVal')
                    // Replace the labels with the specific ones for this case.
                    .map((v, i) => ({ ...v, label: labels[i] }))
                    .value()
                );
              }

              return _(values)
                .map((v, i) => ({
                  value: i,
                  origVal: v,
                  label: `${v * 100}%`,
                  initial: i === 0
                }))
                .sortBy('origVal')
                .value();
            }

            return values.map((v, i) => ({
              value: i,
              origVal: v,
              label: v,
              initial: i === 0
            }));
          })
          .value();

        return {
          ...param,
          id,
          values: paramValues
        };
      });

      setBaseData({
        modelParameters,
        blurbs: blurbs.data,
        metadata: metadata.data,
        oilFields: oilFields.data,
        prices: prices.data,
        related: related.data,
        info,
        countries,
        regions,
        types,
        opecCountries,
        scenarios: scenarios.data
      });

      setBaseDataLoading(false);
    }

    dataLoader();
  }, []);

  const contextValue = {
    baseDataLoading,
    baseData
  };

  return (
    <BaseDataContext.Provider value={contextValue}>
      {children}
    </BaseDataContext.Provider>
  );
};

BaseDataProvider.propTypes = {
  children: T.node
};

// Context consumers.
export const useBaseData = () => {
  const { baseDataLoading, baseData } = useContext(BaseDataContext);

  return {
    isLoading: baseDataLoading,
    ...baseData
  };
};
