// this file was required to be able to load from gulpfile before
// Now serving as a config file for production
module.exports = {
  appTitle: 'Oil Climate Index plus Gas',
  appShortTitle: 'OCI⁺',
  appDescription: 'Assessing Global Oil and Gas Emissions',
  apiUrl: '',
  baseUrl: '',
  mbToken:
    'pk.eyJ1Ijoicm1pLWNpcCIsImEiOiJja3ZzeDB6cWkwM2cyMm5rdGhyZW5rcXltIn0.ZnYuexDuTZGcN-btmoeCTQ'
};
