import { createUITheme } from '@devseed-ui/theme-provider';

export function themeOverridesOCI() {
  const secondary = '#45CFCC'; // Energy

  return createUITheme({
    color: {
      // Basalt
      base: '#58595B',
      // Blue Spruce
      primary: '#003B63',
      // Energy
      secondary: '#45CFCC',
      // Pomegranate (red)
      danger: '#c0392b',
      // Turquoise
      success: '#1abc9c',
      // Orange
      warning: '#f39c12',
      // Belize hole (blue)
      info: '#2980b9',

      link: secondary,
      linkOnDark: '#3f93eb'
    },
    type: {
      base: {
        family: '"source-sans-pro", sans-serif'
      },
      heading: {
        family: '"interstate", sans-serif',
        weight: 700,
        settings: '"wdth" 72, "wght" 760'
      }
    },
    layout: {
      // The gap is defined as a multiplier of the layout.space The elements
      // that use the gap should use it as a parameter for the glsp function
      gap: {
        xsmall: 1,
        small: 1,
        medium: 2,
        large: 2,
        xlarge: 3
      }
    }
  });
}
